<template>
  <v-card outlined class="pa-4 font-weight-bold">
    <v-card-title class="font-weight-bold">Impact / Spend</v-card-title>
    <HorizontalBar :chartData="chartData" :options="options"></HorizontalBar>
  </v-card>
</template>

<script>
import { formatCurrency, numberWithCommas } from "@/utils";

export default {
  name: "ImpactSpendBarChart",
  components: {
    HorizontalBar: () => import("@/components/chart/HorizontalBar"),
  },
  computed: {
    chartData() {
      return this.$store.getters.promoOverviewHorizontalBarChartImpactSpendData;
    },
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
    options() {
      const gridlines_color = this.$vuetify.theme.dark
        ? "rgba(255, 255, 255, 0.1)"
        : "rgba(0, 0, 0, 0.1)";
      const fontColor = this.$vuetify.theme.themes[this.theme].chartFont;
      return {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          labels: {
            fontColor: fontColor,
          },
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                color: gridlines_color,
                zeroLineColor: gridlines_color,
              },
              ticks: {
                beginAtZero: true,
                fontColor: fontColor,
                callback: function(value) {
                  if (parseInt(value) >= 1000) {
                    return value
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                  } else {
                    return value;
                  }
                },
              },
            },
          ],
          yAxes: [
            {
              gridLines: {
                color: gridlines_color,
                zeroLineColor: gridlines_color,
              },
              ticks: {
                fontColor: fontColor,
              },
            },
          ],
        },
        tooltips: {
          callbacks: {
            label: function(tooltipItem) {
              if (tooltipItem.datasetIndex === 0) {
                return numberWithCommas(tooltipItem.xLabel);
              } else {
                return formatCurrency(tooltipItem.xLabel);
              }
            },
          },
        },
      };
    },
  },
  methods: {
    formatCurrency,
    numberWithCommas,
  },
};
</script>

<style scoped></style>
